@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --foreground: #171717;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: Arial, Helvetica, sans-serif;
}

@keyframes float-1 {
  0%, 100% {
    transform: translateX(-100%) translateY(0);
  }
  50% {
    transform: translateX(100%) translateY(-20px);
  }
}

@keyframes float-2 {
  0%, 100% {
    transform: translateX(100%) translateY(0);
  }
  50% {
    transform: translateX(-100%) translateY(-20px);
  }
}

.cloud-1 {
  animation: float-1 20s infinite linear;
  top: 20%;
}

.cloud-2 {
  animation: float-2 25s infinite linear;
  top: 60%;
}
